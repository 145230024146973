import React, {useEffect} from 'react'

function Zoom() {
  useEffect(() => {
    window.location.href = 'https://zoom.us/j/96402108878'
  }, [])
  return <div></div>
}

export default Zoom
