import React, {useEffect, useState} from 'react'
import './App.css'

import {FacebookShareButton, TwitterShareButton} from 'react-share'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core'
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons'
import Zoom from './Zoom'
import {Route, Switch} from 'react-router-dom'

const testURL = 'https://www.homeworkduringhomework.com'
library.add(faFacebook, faTwitter, faInstagram)
const zoomLink = 'https://zoom.us/j/96402108878'

function App() {
  const [time, setTime]: any = useState(new Date())
  const endTime: any = new Date('02/25/2021')
  endTime.setHours(9)

  let seconds: number | string = Math.floor((endTime - time) / 1000)
  let minutes: number | string = Math.floor(seconds / 60)
  let hours: number | string = Math.floor(minutes / 60)
  let days: number | string = Math.floor(hours / 24)

  hours = hours - days * 24
  minutes = minutes - days * 24 * 60 - hours * 60
  seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60

  if (hours < 10) {
    hours = '0' + hours
  }
  if (minutes < 10) {
    minutes = '0' + minutes
  }

  if (seconds < 10) {
    seconds = '0' + seconds
  }

  const isPassed = endTime - time < 0

  const hashTagToPass = '#homeworkduringhomework'
  const quoteToPass = !isPassed
    ? 'Working from home doing zoom calls? Here’s a zoom call link to listen to Daft Punk’s Homework with a bunch of strangers.'
    : 'I’m in a zoom call listening to Daft Punk’s Homework while working from home. I’m in right now. See you there!'

  useEffect(() => {
    setInterval(() => {
      setTime(new Date())
    }, 1000)
  }, [])

  return (
    <div
      className="App"
      style={{
        backgroundImage: 'url("/assets/images/background-2.png")',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Switch>
        <Route exact path="/zoom" component={Zoom} />
      </Switch>
      <div className="centered-container">
        <img className="logo-img" src="/assets/images/logo-def.png" alt="" />
        <p>
          After 28 years of pushing the boundaries of
          <br /> electronic music, Daft Punk is breaking up.
        </p>
        <p>
          We can't say goodbye to them live,
          <br /> because we all seem to be stuck at home.
        </p>
        <p>
          So we launched a zoom call to listen to a continuous
          <br /> loop of Homework while working from home.
          <br />
          Open during office hours.
        </p>

        <div className="link-container">
          <strong style={{paddingBottom: isPassed ? '2em' : 0}}>
            JOIN THE CALL.
          </strong>
          <p
            style={{
              color: 'white',
              fontWeight: 'bold',
              display: isPassed ? 'none' : 'unset',
            }}
          >
            {hours} : {minutes} : {seconds}
          </p>
          <a href={zoomLink} target="_blank" rel="noreferrer">
            zoom.us/j/96402108878
          </a>
          <span>meeting ID: 964 0210 8878</span>
          <span>Passcode: 19932021</span>
        </div>
      </div>
      <div className="footer">
        <div
          className="footer-text"
          style={{
            fontFamily: 'StuBru',
            marginLeft: '0.5em',
            fontSize: '0.6em',
            color: '#999999',
          }}
        >
          Door deel te nemen geef je toestemming om opgenomen te worden.
        </div>
        <div className="social-icons">
          <FacebookShareButton
            url={testURL}
            quote={quoteToPass}
            hashtag={hashTagToPass}
            children={<FontAwesomeIcon icon={['fab', 'facebook']} />}
          />
          <TwitterShareButton
            url={testURL}
            title={quoteToPass}
            hashtags={['homeworkduringhomework']}
            children={<FontAwesomeIcon icon={['fab', 'twitter']} />}
          />
        </div>
        <div className="logo">
          <img src="assets/images/stubru-logo.png" alt="Studio Brussel" />
        </div>
      </div>
    </div>
  )
}

export default App
